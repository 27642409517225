<template>
    <div>
        <div class="prtf-item-info-panel">
            <div class="prtf-item-info-panel-l">
                <el-switch
                    v-model="show_closed"
                    active-text="Show sold"
                    inactive-text=""
                    v-if="showsold"
                    @change="get_grid_data">
                </el-switch>
            </div>
            <div class="prtf-item-info-panel-r">
                <el-popover
                    placement="bottom"
                    width="300"
                    trigger="click"
                    popper-class="tl-config-popover"
                    ref="ColumnsPopover">

                    <div>
                        <draggable
                            :list="columns_config"
                            class="tl-config-list-group"
                            ghost-class="tl-config-ghost">
                            <div
                                class="tl-config-list-group-item"
                                v-for="col in columns_config"
                                :key="col.field"
                                v-if="col.title">
                                <el-checkbox v-model="col.active"></el-checkbox> {{ col.title }}
                            </div>
                        </draggable>
                        <div class="tl-config-list-buttons">
                            <el-button @click="restore_columns" size="small">Restore</el-button>
                            <div class="spacer" />
                            <el-button type="primary" @click="save_columns" size="small">Confirm</el-button>
                        </div>
                    </div>

                    <el-button class="prtf-item-columns-btn" type="text" slot="reference" v-tooltip="'Configure Table Columns'">
                        <svg-icon icon-class="filter" />
                    </el-button>
                </el-popover>
            </div>
        </div>

        <vxe-grid
            v-loading="loading"
            :columns="columns"
            :data="grid_data"
            size="mini"
            ref="DataGrid"
            auto-resize
            class="analytics-grid"
            v-bind="grid_options"
            :sort-config="{remote: true}"
            :menu-config="context_menu_config"
            @cell-menu="cell_context_menu_event"
            @menu-click="context_menu_click_event"
            highlight-current-row
            :max-height="max_height">
                <template v-slot:col_header="{ column }">
                    <div class="ag-header-title-wrapper"  @click="sort_table(column)">
                        <div v-if="column.sortable" class="ag-header-sort">
                            <div class="spacer" />
                            <div class="ag-header-sort-top" v-bind:class="{ active: column.params && column.params.order === 'asc' }"><i class="el-icon-caret-top"></i></div>
                            <div class="ag-header-sort-bottom" v-bind:class="{ active: column.params && column.params.order === 'desc' }"><i class="el-icon-caret-bottom"></i></div>
                            <div class="spacer" />
                        </div>
                        <div class="ag-header-title">{{column.title}}</div>
                    </div>
                </template>

                <template v-slot:col_name="{ row, column }">
                    <div v-if="summary" class="ag-tree_row">
                        <span>{{row.asset_name}}</span>
                        <span v-if="row.currency" class="ag-tree_row-cur">{{row.currency}}</span>
                    </div>

                    <div v-else class="ag-tree_row-portfolio">
                        <div v-if="row.level == 1" class="ag-tree_row-portfolio-l1">
                            {{row.name}}
                        </div>
                        <div v-if="row.level == 2" class="ag-tree_row-portfolio-l2" @click="toggle_asset(row)">
                            <div v-if="row.children && row.children.length" class="ag-asset-arrow">
                                <svg-icon icon-class="chevron_up" v-if="$refs.DataGrid.isTreeExpandByRow(row)" />
                                <svg-icon icon-class="chevron_down" v-else />
                            </div>
                            <div v-else class="ag-asset-arrow"></div>
                            {{row.name}}
                        </div>
                        <div v-if="row.level == 3" class="ag-tree_row-portfolio-l3" @click="toggle_instrument(row)">
                            <div class="ag-asset-arrow">
                                <svg-icon icon-class="chevron_up" v-if="$refs.DataGrid.isExpandByRow(row)" />
                                <svg-icon icon-class="chevron_down" v-else />
                            </div>
                            <div>
                                <span v-if="row.asset=='bond'">{{row.name}}</span>
                                <span v-else-if="row.asset=='future'">{{row.isin}}</span>
                                <span v-else-if="row.asset=='cash'">{{row.isin}}</span>
                                <span v-else>{{row.ticker || row.name}}</span>
                                <span v-if="row.pt !== 'long'">&nbsp;[{{row.pt}}]</span>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:expandable="{ row }">
                    <div class="ag-expand-content">
                        <div class="ag-expand-content-l">
                            <div class="ag-ecl-title">{{row.issuer_name || row.name}}</div>
                            <div class="ag-ecl-row" v-if="row.isin && row.asset !== 'cash'">
                                <div class="ag-ecl-row-title">ISIN:</div>
                                <div class="ag-ecl-row-value">{{row.isin}}</div>
                            </div>
                            <div class="ag-ecl-row" v-if="row.ticker">
                                <div class="ag-ecl-row-title">Ticker:</div>
                                <div class="ag-ecl-row-value">{{row.ticker}}</div>
                            </div>
                            <div class="ag-ecl-row" v-if="row.pt !== 'long'">
                                <div class="ag-ecl-row-title">Position Type:</div>
                                <div class="ag-ecl-row-value">{{row.pt}}</div>
                            </div>
                            <div class="ag-ecl-row" v-if="row.ac_code_name">
                                <div class="ag-ecl-row-title">Asset Category:</div>
                                <div class="ag-ecl-row-value">{{row.ac_code_name}}</div>
                            </div>
                            <div class="ag-ecl-row" v-if="row.ac_code_name">
                                <div class="ag-ecl-row-title">Last Price:</div>
                                <div class="ag-ecl-row-value">{{row.last_price}} {{row.currency}}</div>
                            </div>

                            <div class="ag-ecl-addinfo" v-if="row.asset === 'bond'">
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Maturity Date:</div>
                                    <div class="ag-ecl-row-value"><FormattedDate :value="row.maturity" /></div>
                                </div>
                            </div>

                            <div class="ag-ecl-addinfo" v-if="row.asset === 'equity'">
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">RIC:</div>
                                    <div class="ag-ecl-row-value">{{row.ric}}</div>
                                </div>
                            </div>

                            <div class="ag-ecl-addinfo" v-if="row.asset === 'future'">
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Base Date:</div>
                                    <div class="ag-ecl-row-value">{{row.basedate}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Expiration Date:</div>
                                    <div class="ag-ecl-row-value">{{row.expdate}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Tick size:</div>
                                    <div class="ag-ecl-row-value">{{row.ticks}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Tick value:</div>
                                    <div class="ag-ecl-row-value">{{row.tickv}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Underlying instrument:</div>
                                    <div class="ag-ecl-row-value">{{row.uname}}</div>
                                </div>
                            </div>

                            <div class="ag-ecl-addinfo" v-if="row.asset === 'option'">
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Base Date:</div>
                                    <div class="ag-ecl-row-value">{{row.basedate}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Expiration Date:</div>
                                    <div class="ag-ecl-row-value">{{row.expdate}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Type:</div>
                                    <div class="ag-ecl-row-value">{{row.type}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Strike Price:</div>
                                    <div class="ag-ecl-row-value">{{row.strike}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Lot Size:</div>
                                    <div class="ag-ecl-row-value">{{row.size}}</div>
                                </div>
                                <div class="ag-ecl-row">
                                    <div class="ag-ecl-row-title">Underlying instrument:</div>
                                    <div class="ag-ecl-row-value">{{row.uname}}</div>
                                </div>
                            </div>

                            <el-button type="primary" plain @click="open_instrument_card(row)">Full Details</el-button>
                        </div>
                        <div class="ag-expand-content-r" :set="current_price = row.cur_p">
                            <div class="ag-expand-accounts-title">Accounts</div>
                            <vxe-table :data="row.accounts_balance">
                                <vxe-table-column field="account" title="Account" min-width="150"></vxe-table-column>
                                <vxe-table-column field="weight"  title="Weight" min-width="90">
                                    <template v-slot="{ row }">
                                        <FormattedNumber :value="row.weight" zeros format="0,0.[00]%" />
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column title="Position" min-width="110">
                                    <template v-slot="{ row }">
                                        <FormattedNumber :value="row.balance" zeros sign color format="0,0.[00]" />
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column title="Available" min-width="110">
                                    <template v-slot="{ row }">
                                        <FormattedNumber :value="row.available_balance" zeros sign color format="0,0.[00]" />
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column title="Blocked" min-width="110">
                                    <template v-slot="{ row }">
                                        <FormattedNumber :value="row.blocked_balance" zeros sign color format="0,0.[00]" />
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column field="mv" title="Market Value" min-width="130">
                                    <template v-slot="{ row }">
                                        <FormattedNumber :value="count_market_value(current_price, row.balance)" zeros sign color format="0,0.[00]" />
                                    </template>
                                </vxe-table-column>
                            </vxe-table>
                        </div>
                    </div>
                </template>

                <template v-slot:col_colored="{ row, column }">
                    <div v-if="summary || !column.params.no_summary || row.level >= 3">
                        <FormattedNumber
                            :value="row[column.property]"
                            :decimals="column.params.format.decimals"
                            :percents="column.params.format.percents"
                            :zeros="column.params.format.zeros"
                            :sign="column.params.format.sign"
                            :color="column.params.format.color"
                            :format="column.params.format.format"
                             />
                    </div>
                </template>
        </vxe-grid>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Urls from '@/../config/url.js'
import draggable from 'vuedraggable'
import {firstBy} from "thenby"
import XEUtils from 'xe-utils'
import FormattedNumber from '@/components/Formats/FormattedNumber'
import FormattedDate from '@/components/Formats/FormattedDate'

export default {
    components: { draggable, FormattedNumber, FormattedDate },
    props: {
        expandable: {
            type    : Boolean,
            default : false,
        },
        showsold: {
            type    : Boolean,
            default : false,
        },
        summary: {
            type    : Boolean,
            default : false,
        },
        summary_mode: {
            type    : String,
            default : 'assets',
        },
        portfolio: {
            type    : String,
        },
        portfolio_mode: {
            type    : String,
        },
        portfolio_date: {
            type    : Date,
        },
        source_list: {
            type    : Array,
            required: true
        },
        source_loading: {
            type    : Boolean,
            default : false,
        },
        client: {
            type    : Object,
            required: true
        },
        transfer_available: {
            type: Boolean,
            default: false,
        },
        max_height: {
            type: Number,
            default: 0,
        },
    },

    data(){
        return {
            show_closed          : false,
            columns_config       : [],
            columns              : [],
            grid_data            : [],
            user_settings_record : undefined,
            loading              : false,

            init_columns: [
                {
                    field     : 'name',
                    title     : '',
                    minWidth  : 300,
                    slots     : { default: 'col_name', header: 'col_header', content: 'expandable' },
                    no_config : true,
                    sortable  : true,
                    fixed     : 'left',
                    type      : 'expand',
                    params    : {
                        order: undefined,
                    },
                },{
                    field      : 'isin',
                    title      : 'ISIN',
                    minWidth   : 120,
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'issuer_name',
                    title      : 'Issuer Name',
                    minWidth   : 200,
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'currency',
                    title      : 'Сurr.',
                    minWidth   : 85,
                    align      : 'center',
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field    : 'w',
                    title    : 'Weight',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order             : undefined,
                        only_summary_view : true,
                        format   : {
                            decimals : 2,
                            percents : true,
                            zeros    : false,
                            sign     : false,
                            color    : true,
                        },
                    },
                },{
                    field    : 'weight_p',
                    title    : 'Weight in Portfolio',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order           : undefined,
                        no_summary_view : true,
                        format   : {
                            format : '0.00%',
                            zeros  : false,
                            sign   : false,
                            color  : true,
                        },
                    },
                },{
                    field    : 'weight_a',
                    title    : 'Weight in Asset',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order           : undefined,
                        no_summary_view : true,
                        format   : {
                            format : '0.00%',
                            zeros  : false,
                            sign   : false,
                            color  : true,
                        },
                    },
                },{
                    field      : 'bdt',
                    title      : 'Date Opened',
                    minWidth   : 95,
                    formatter  : "formatDate",
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'sdt',
                    title      : 'Date Closed',
                    minWidth   : 95,
                    formatter  : "formatDate",
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'q',
                    title      : 'Position',
                    minWidth   : 100,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : true,
                        },
                    },
                },{
                    field      : 'q_av',
                    title      : 'Available Position',
                    minWidth   : 100,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : true,
                        },
                    },
                },{
                    field      : 'q_block',
                    title      : 'Blocked Position',
                    minWidth   : 100,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : true,
                        },
                    },
                },{
                    field      : 'avg_p',
                    title      : 'Avg. Cost',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : false,
                        },
                    }
                },{
                    field      : 'avg_p_fx',
                    title      : 'Avg. FX Cost',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : false,
                        },
                    }
                },{
                    field      : 'avg_p_price',
                    title      : 'Avg. Price Cost',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : false,
                        },
                    }
                },{
                    field      : 'cur_p',
                    title      : 'Close Price in P.Curr',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order           : undefined,
                        no_summary      : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : false,
                        }
                    },
                },{
                    field    : 'mv',
                    title    : 'Market Value',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : true,
                        }
                    }
                },{
                    field      : 'last_price',
                    title      : 'Current Price in Own Curr.',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : true,
                            sign      : false,
                            color     : false,
                        },
                    },
                },{
                    field    : 'daily_fees',
                    title    : 'Fees 1d',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'fees',
                    title    : 'Fees',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'daily_rpl',
                    title    : 'Realized P&L 1d',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'rpl',
                    title    : 'Realized P&L',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'daily_rpl_fx',
                    title    : 'Realized FX P&L 1d',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'rpl_fx',
                    title    : 'Realized FX P&L',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'daily_rpl_price',
                    title    : 'Realized Price P&L 1d',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'rpl_price',
                    title    : 'Realized Price P&L',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl_price',
                    title    : 'Unr. Price P&L',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl_fx',
                    title    : 'Unr. FX P&L',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },
                {
                    field    : 'upl_p',
                    title    : 'Unr. Total P&L,%',
                    minWidth : 85,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl',
                    title    : 'Unr. Total P&L',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field      : 'accruals',
                    title      : 'Accruals',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order: undefined,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        },
                    }
                },{
                    field      : 'cash_income',
                    title      : 'Cash Income',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order: undefined,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : false,
                            sign      : true,
                            color     : true,
                        },
                    }
                },{
                    field      : 'accrued_coupon',
                    title      : 'Accrued Coupon',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : false,
                            sign      : false,
                            color     : false,
                        },
                    }
                },{
                    field      : 'coupon_rate',
                    title      : 'Coupon Rate',
                    minWidth   : 95,
                    align      : 'right',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                        format     : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : false,
                            sign      : false,
                            color     : false,
                        }
                    },
                },{
                    field      : 'coupon_type',
                    title      : 'Coupon Type',
                    minWidth   : 95,
                    align      : 'center',
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'maturity',
                    title      : 'Maturity',
                    minWidth   : 95,
                    align      : 'center',
                    formatter  : "formatDate",
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field    : 'upl_1d',
                    title    : 'Unr. P&L 1d',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl_1d_p',
                    title    : 'Unr. P&L 1d, %',
                    minWidth : 90,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        },
                    }
                },{
                    field    : 'upl_1w',
                    title    : 'Unr. P&L 1w',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl_1w_p',
                    title    : 'Unr. P&L 1w, %',
                    minWidth : 90,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl_1m',
                    title    : 'Unr. P&L 1m',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl_1m_p',
                    title    : 'Unr. P&L 1m, %',
                    minWidth : 90,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl_1y',
                    title    : 'Unr. P&L 1y',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'upl_1y_p',
                    title    : 'Unr. P&L 1y, %',
                    minWidth : 90,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 'd_cash_flow',
                    title    : 'Daily Cash Flow',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field    : 't_cash_flow',
                    title    : 'Total Cash Flow',
                    minWidth : 110,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        format   : {
                            decimals  : 2,
                            percents  : false,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        }
                    },
                },{
                    field      : 'sector',
                    title      : 'Sector',
                    minWidth   : 100,
                    align      : 'left',
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'industry_group',
                    title      : 'Industry Group',
                    minWidth   : 200,
                    align      : 'left',
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'country',
                    title      : 'Country',
                    minWidth   : 180,
                    align      : 'left',
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'fitch_rating',
                    title      : 'Fitch Rating',
                    minWidth   : 90,
                    align      : 'left',
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'moody_rating',
                    title      : 'Moody Rating',
                    minWidth   : 90,
                    align      : 'left',
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 's&p_rating',
                    title      : 'S&P Rating',
                    minWidth   : 90,
                    align      : 'left',
                    sortable   : true,
                    slots      : { header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                    },
                },{
                    field      : 'db_beta',
                    title      : 'Beta',
                    minWidth   : 90,
                    align      : 'left',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                        format   : {
                            decimals  : 4,
                            percents  : false,
                            zeros     : false,
                            sign      : false,
                            color     : false,
                        }
                    },
                },{
                    field      : 'beta_a_contrib',
                    title      : 'Beta Asset Contribution',
                    minWidth   : 110,
                    align      : 'left',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary_view : true,
                        format   : {
                            decimals  : 6,
                            percents  : false,
                            zeros     : false,
                            sign      : false,
                            color     : false,
                        }
                    },
                },{
                    field      : 'beta_p_contrib',
                    title      : 'Beta Portfolio Contribution',
                    minWidth   : 110,
                    align      : 'left',
                    sortable   : true,
                    slots      : { default: 'col_colored', header: 'col_header' },
                    params     : {
                        order: undefined,
                        no_summary_view : true,
                        format   : {
                            decimals  : 6,
                            percents  : false,
                            zeros     : false,
                            sign      : false,
                            color     : false,
                        }
                    },
                },{
                    field    : 'price_ch_1d',
                    title    : 'Price Change 1d, %',
                    minWidth : 90,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        },
                    }
                },{
                    field    : 'price_ch_1w',
                    title    : 'Price Change 1w, %',
                    minWidth : 90,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        },
                    }
                },{
                    field    : 'price_ch_1m',
                    title    : 'Price Change 1m, %',
                    minWidth : 90,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        },
                    }
                },{
                    field    : 'price_ch_1y',
                    title    : 'Price Change 1y, %',
                    minWidth : 90,
                    align    : 'right',
                    sortable : true,
                    slots    : { default: 'col_colored', header: 'col_header' },
                    params   : {
                        order: undefined,
                        no_summary : true,
                        no_summary_view : true,
                        format   : {
                            decimals  : 2,
                            percents  : true,
                            zeros     : true,
                            sign      : true,
                            color     : true,
                        },
                    }
                },
            ]
        }
    },

    computed: {
        ...mapState({
            user           : state => state.app.user,
        }),
        get_db_record_params(){
            let params = {
                "data.user_id": this.user.id,
            }
            if (this.summary) {
                params["data.type"] = 'portfolio_summary'
            }
            else {
                params["data.type"] = 'portfolio' + this.portfolio
            }
            return params
        },
        post_db_record_params(){
            let params = {
                data: {
                    user_id: this.user.id,
                }
            }
            if (this.summary) {
                params.data.type = 'portfolio_summary'
            }
            else {
                params.data.type = 'portfolio' + this.portfolio
            }
            return params
        },
        grid_options(){
            let options = {}

            options.treeConfig = {
                children: 'children',
                expandAll: true,
            }

            options.expandConfig = {
                visibleMethod: this.expand_visible_method,
                lazy: true,
                loadMethod: this.expand_load_method,
            }

            return options
        },
        initial_columns(){
            let columns = JSON.parse(JSON.stringify(this.init_columns))

            if (this.summary) {
                columns = columns.filter(obj => { return !obj.params.no_summary_view; });
            }
            else {
                columns = columns.filter(obj => { return !obj.params.only_summary_view; });
            }

            return columns
        },
        context_menu_config(){
            if (this.summary && this.summary_mode === 'assets') return {};
            else if (this.summary && this.summary_mode === 'portfolios') return {
                body: {
                    options: [
                        [
                            { code: 'open_portfolio', name: 'Open', visible: true, disabled: false },
                        ]
                    ]
                }
            };
            return {
                body: {
                    options: [
                        [
                            { code: 'buy', name: 'Buy', visible: true, disabled: false, className:'green' },
                            { code: 'cover_short', name: 'Cover Short', visible: true, disabled: false, className:'green' },
                        ],
                        [
                            { code: 'sell', name: 'Sell', visible: true, disabled: false, className:'red' },
                            { code: 'sell_short', name: 'Sell Short', visible: true, disabled: false, className:'red' },
                        ],
                        [
                            { code: 'block', name: 'Collateral Block', visible: true, disabled: false },
                            { code: 'unblock', name: 'Collateral Unblock', visible: true, disabled: false },
                        ],
                        [
                            { code: 'deposit', name: 'Deposit', visible: true, disabled: false },
                            { code: 'withdraw', name: 'Withdraw', visible: true, disabled: false },
                            { code: 'transfer', name: 'Transfer', visible: true, disabled: false },
                        ],
                        [
                            { code: 'info', name: 'Info', visible: true, disabled: false },
                            { code: 'corp_action', name: 'Corporate Action', visible: true, disabled: false },
                            { code: 'accounting', name: 'Accounting', visible: true, disabled: false },
                        ],
                        [
                            { code: 'expand', name: 'Expand', visible: true, disabled: false, className:'green' },
                            { code: 'collapse', name: 'Collapse', visible: true, disabled: false },
                        ],
                    ]
                },
                visibleMethod: this.context_menu_visible_method
            }
        }
    },

    methods: {
        get_grid_columns(){
            let url = Urls.userSettings + "?max_results=1&ts=" + new Date().getTime() + "&where=" + JSON.stringify(this.get_db_record_params)
            this.axios.get(url)
                .then(response => {
                    this.user_settings_record = response.data._items[0]
                    if (this.user_settings_record) {
                        let columns = []
                        this.initial_columns.forEach((col, index) => {
                            if (!col.no_config) {
                                let col_conf = this.user_settings_record.columns.find(c => c.field === col.field)
                                columns.push({
                                    field  : col.field,
                                    title  : col.title,
                                    active : (col_conf && col_conf.active) ? true : false,
                                    sort   : index + 1
                                });
                            }   
                        })
                        this.columns_config = columns
                    }
                    else {
                        this.restore_init_columns();
                    }
                    this.apply_columns_config()
                })
                .catch(e => {
                    this.$store.dispatch('app/process_error', e)
                })
        },

        apply_columns_config(){
            this.columns = JSON.parse(JSON.stringify(this.initial_columns))
            this.columns_config.forEach((col, index) => {
                col.sort = index + 3
            })
            this.columns.forEach((col, index) => {
                let col_conf = this.columns_config.find(c => c.field === col.field)
                if (col_conf) {
                    col.visible = col_conf.active
                    col.colorder = col_conf.sort
                }
                else if (col.no_config) {
                    col.visible = true
                    col.colorder = index + 1
                }
                else {
                    col.visible = false
                }
            })
            this.columns.sort(firstBy("colorder"));
            this.$refs.DataGrid.reloadColumn(this.columns)
            this.set_init_tree_expand()
        },

        restore_init_columns(){
            let columns = []
            this.initial_columns.forEach((col, index) => {
                if (!col.no_config)
                    columns.push({
                        field  : col.field,
                        title  : col.title,
                        active : true,
                        sort   : index + 1
                    });
            })
            this.columns_config = columns
        },

        restore_columns(){
            this.restore_init_columns();
            this.save_columns();
        },

        save_columns(){
            this.$refs.ColumnsPopover.doToggle()
            this.loading = true;
            if (this.user_settings_record) {
                this.axios.patch(Urls.userSettings + this.user_settings_record._id, {
                    columns: this.columns_config,
                },{ headers: {
                    'If-Match': this.user_settings_record._etag
                }})
                .then(response => {
                    this.refresh_user_settings_record(response.data._id).then(() => {
                        this.apply_columns_config();
                        this.loading = false;
                    })
                })
                .catch(e => {
                    this.$store.dispatch('app/process_error', e)
                })
            }
            else {
                let params = JSON.parse(JSON.stringify(this.post_db_record_params))
                params.columns = this.columns_config
                this.axios.post(Urls.userSettings, params)
                .then(response => {
                    this.refresh_user_settings_record(response.data._id).then(() => {
                        this.apply_columns_config();
                        this.loading = false;
                    })
                })
                .catch(e => {
                    this.$store.dispatch('app/process_error', e)
                })
            }
        },

        refresh_user_settings_record(record_id){
            return this.axios.get(Urls.userSettings + record_id)
                .then(response => {
                    this.user_settings_record = response.data
                })
                .catch(e => {
                    this.$store.dispatch('app/process_error', e)
                })
        },

        get_grid_data(field, order, reload_data){
            if (!this.source_list.length) return;

            let key = 'id';
            let parentKey = 'parent_id'

            if (this.summary){
                if (field === 'name') field = 'asset_name';
                key = 'asset_name'
                parentKey = 'asset_parent'
            }  

            let list = this.source_list
            list = list.filter(instrument => {
                return instrument.mv != 0 || instrument.rpl != 0 || instrument.pl != 0 || instrument.pl_p != 0  || instrument.show
            }) 
            if (!this.show_closed)
                list = list.filter(instrument => {
                    return ((instrument.q != 0 || instrument.q_block !== 0 || instrument.q_av !== 0) && instrument.level == 3) || instrument.level != 3
                })       

            if (!order){
                this.grid_data = XEUtils.toArrayTree(list, { key: key, parentKey: parentKey, sortKey: 'isin', reverse: false });
            }
            else if (order === 'asc') {
                this.grid_data = XEUtils.toArrayTree(list, { key: key, parentKey: parentKey, sortKey: field, reverse: false });
            }
            else {
                this.grid_data = XEUtils.toArrayTree(list, { key: key, parentKey: parentKey, sortKey: field, reverse: true });
            }

            if (reload_data) this.$refs.DataGrid.reloadData(this.grid_data);
            this.set_init_tree_expand()
        },

        sort_table(column){
            if (!column.sortable) return;
            if (!this.$refs.DataGrid) return;

            if (!column.params.order){
                this.apply_columns_sorting_mark(column.property, 'asc');
                this.get_grid_data(column.property, 'asc', false);
            }
            else if (column.params.order === 'desc'){
                this.apply_columns_sorting_mark(undefined, undefined, false);
                this.get_grid_data();
            }
            else if (column.params.order === 'asc'){
                this.apply_columns_sorting_mark(column.property, 'desc');
                this.get_grid_data(column.property, 'desc', false);
            }
        },

        apply_columns_sorting_mark(field, mark){
            this.columns.forEach(col => {
                if (field && field === col.field)
                    col.params.order = mark;
                else
                    col.params.order = undefined
            });
        },

        set_init_tree_expand(){
            if (this.summary) {
                this.$refs.DataGrid.setAllTreeExpand(true)
            }
            else {
                let table_data = this.$refs.DataGrid.getData()
                let summary_row = XEUtils.findTree(table_data, item => item.level === 1)

                if (summary_row && summary_row.item){
                    this.$refs.DataGrid.setTreeExpand(summary_row.item, true);
                }
            }
        },

        expand_visible_method({ row }){
            if (row.level == 3) {
                return true
            }
            return false
        },

        expand_load_method({ row }){
            const $grid = this.$refs.DataGrid
            let dt = this.portfolio_date
            let url = Urls.accountsIsinBalance 
                + '?client_id=' + this.client._id 
                + '&isin=' + row.isin 
                + '&mode=' + this.portfolio_mode 
                + '&portfolio_id=' + this.portfolio 
                + '&date=' + this.$moment(dt).format('YYYY-MM-DD')
            return new Promise(resolve => {
                this.axios.get(url)
                    .then(response => {
                        row.accounts_balance = response.data._items
                        $grid.clearRowExpandLoaded(row)
                        resolve()
                    })
                    .catch(e => {
                        console.log(e)
                        this.$message({
                            message: 'Cannot get Accounts Balance Data!',
                            type: 'error'
                        })
                        $grid.clearRowExpandLoaded(row)
                        resolve()
                    })
            })
        },

        context_menu_visible_method({ type, options, column, row }){
            const $grid = this.$refs.DataGrid
            let row_level2_expanded = $grid.isTreeExpandByRow(row)
            let row_level3_expanded = $grid.isExpandByRow(row)
            options.forEach(list => {
                list.forEach(item => {
                    if (row.level == 1) {
                        item.visible = false;
                    }
                    else if (row.level == 2) {
                        if (item.code === 'expand' && !row_level2_expanded)
                            item.visible = true;
                        else if (item.code === 'collapse' && row_level2_expanded)
                            item.visible = true;
                        else if (['buy','cover_short','sell','sell_short','deposit','withdraw'].includes(item.code))
                            item.visible = true;
                        else
                            item.visible = false;
                    }
                    else {
                        if (item.code === 'expand'){
                            item.visible = (row_level3_expanded) ? false : true;
                        }
                        else if (item.code === 'collapse'){
                            item.visible = (row_level3_expanded) ? true : false;
                        }
                        else {
                            item.visible = true;
                            if (item.code === 'corp_action') {
                                if (['equity','bond'].includes(row.asset))
                                    item.disabled = false;
                                else item.disabled = true;
                            }
                            if (item.code === 'transfer') {
                                // if (this.transfer_available)
                                //     item.disabled = false;
                                // else item.disabled = true;
                                item.disabled = false;
                            }
                            if (item.code === 'varmargin') {
                                if (['future'].includes(row.asset))
                                    item.disabled = false;
                                else item.disabled = true;
                            }
                            if (item.code === 'cover_short') {
                                if (row.pt === 'short')
                                    item.disabled = false;
                                else item.disabled = true;
                            }
                            if (item.code === 'block') {
                                if (row.pt === 'long')
                                    item.disabled = false;
                                else item.disabled = true;
                            }
                            if (item.code === 'unblock') {
                                if (row.q_block && row.q_block !== 0)
                                    item.disabled = false;
                                else item.disabled = true;
                            }
                            if (item.code === 'deposit') {
                                if (['future'].includes(row.asset))
                                    item.disabled = true;
                                else item.disabled = false;
                            }
                            if (item.code === 'withdraw') {
                                if (['future'].includes(row.asset))
                                    item.disabled = true;
                                else item.disabled = false;
                            }
                        }
                    }
                })
            })
            return true
        },

        cell_context_menu_event({ row }){
            const $grid = this.$refs.DataGrid
            $grid.setCurrentRow(row)
        },

        context_menu_click_event({ menu, row, column }){
            const $grid = this.$refs.DataGrid
            switch (menu.code) {
                case 'expand':
                    if (row.level == 2)
                        this.toggle_asset(row);
                    else
                        this.toggle_instrument(row);
                    break

                case 'collapse':
                    if (row.level == 2)
                        this.toggle_asset(row);
                    else
                        this.toggle_instrument(row);
                    break

                case 'buy':
                    this.open_operation_form('buy', row)
                    break

                case 'cover_short':
                    this.open_operation_form('cover_short', row)
                    break

                case 'sell':
                    this.open_operation_form('sell', row)
                    break

                case 'sell_short':
                    this.open_operation_form('sell_short', row)
                    break

                case 'deposit':
                    this.open_operation_form('deposit', row)
                    break

                case 'withdraw':
                    this.open_operation_form('withdraw', row)
                    break

                case 'block':
                    this.open_operation_form('collateral_block', row)
                    break

                case 'unblock':
                    this.open_operation_form('collateral_unblock', row)
                    break

                case 'transfer':
                    this.$message('Not implemented yet')
                    break

                case 'info':
                    this.open_instrument_card(row)
                    break

                case 'corp_action':
                    this.open_operation_form('corp_action', row)
                    break

                case 'accounting':
                    this.open_accounting_calculations(row)
                    break

                case 'open_portfolio':
                    this.open_portfolio(row)
            }
        },

        open_operation_form(operation_type, data){
            let params = {type: operation_type}

            if (operation_type == 'corp_action' && data.asset && data.asset === 'equity')
                params.type = 'dividend';
            else if (operation_type == 'corp_action' && data.asset && data.asset === 'bond')
                params.type = 'coupon';
            else if (operation_type == 'corp_action')
                return;

            if (data.asset)
                params.asset = data.asset;
            if (data.isin)
                params.isin = data.isin;

            this.$router.push({ path: '/new-operation/' + this.client.id + '/' + this.portfolio , query: params})
        },

        toggle_asset(row) {
            this.$refs.DataGrid.toggleTreeExpand(row);
        },

        toggle_instrument(row) {
            this.$refs.DataGrid.toggleRowExpand(row);
        },

        open_instrument_card(row) {
            this.$router.push({ path: '/portfolio-instrument/' + this.client.id + '/' + this.portfolio + '/' + row.asset + '/' + row.isin + '/' + row.pt})
        },

        open_accounting_calculations(row) {
            this.$router.push({ path: '/accounting-calculations/' + this.client.id + '/' + this.portfolio + '/' + row.asset + '/' + row.isin + '/' + row.pt})
        },

        open_portfolio(row) {
            this.$router.push({ path: '/portfolio/' + this.client.id + '/' + row.portfolio_id['$oid'] })
        },

        count_market_value(price, balance){
            return price * balance
        }
    },

    mounted(){
        this.$nextTick(() => {
            this.get_grid_columns();
            this.get_grid_data(undefined, undefined, false);
        })
    },

    watch: {
        source_list: {
            handler: function(val) {
                this.get_grid_data(undefined, undefined, true)
            },
            deep: true
        },
        source_loading(val){
            this.loading = val
        }
    }
}
</script>
