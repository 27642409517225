<template>
    <span>{{formated_date}}</span>
</template>

<script>
import { date_to_string } from '@/utils'
export default {
    props: {
        value: {
            required: true
        },
        time: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        formated_date(){
            let format = (this.time) ? 'DD MMM YY HH:mm' : 'DD MMM YY';
            return date_to_string(this.value, format)
        }
    }
}
</script>
