<template>
    <span 
        v-if="value || zeros"
        :class="get_amount_class">
        {{value | numFormat(get_amount_format)}}<span v-if="percents">%</span>
    </span>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        decimals: {
            type: Number,
            default: 2,
        },
        percents: {
            type: Boolean,
            default: false,
        },
        zeros: {
            type: Boolean,
            default: false,
        },
        sign: {
            type: Boolean,
            default: false,
        },
        color: {
            type: Boolean,
            default: false,
        },
        highlight: {
            type: Boolean,
            default: false,
        },
        num_class: {
            type: String,
            default: '',
        },
        format: {
            type: String,
        },
    },

    computed: {
        get_amount_format(){
            if (this.format) return this.format;
            let number_format = '0,0[.][' + Array(this.decimals + 1).join("0") + ']';
            if (this.sign && this.value && this.value.toFixed(8) > 0.00000000)
                number_format = '+' + number_format;
            return number_format
        },

        get_amount_class(){
            let value_class = 'nowrap'
            if (this.num_class) value_class += ' ' + this.num_class;
            if (this.highlight) { 
                value_class += ' cell-highlight';
            }
            else if (this.color) {
                if (this.value.toFixed(8) > 0) value_class += ' cell-positive';
                else if (this.value.toFixed(8) < 0) value_class += ' cell-negative';
            }
            return value_class
        }
    }
}
</script>
